import React, { useState, useEffect } from 'react'
import { Typography, Box, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { PropTypes } from 'prop-types'
import { navigate } from 'gatsby'
import PlayCircleIcon from '../graphics/player/PlayCircle'
import Image from '../PreviewCompatibleImage'
import { withEllipsis } from '../../utils/string'

const useStyles = makeStyles((theme) => ({
  main: {
    background: theme.palette.primary.black,
    transform: 'translate(20px,-100%)',
    opacity: 0,
    transition: 'all 0.4s ease',
    maxWidth: '400px',

    [theme.breakpoints.up('md')]: {
      maxWidth: '550px',
    },
    '&$entered': {
      opacity: 1,
      transform: 'translate(0,-100%)',
    },
  },
  entered: {
  },
  listen: {
    stroke: theme.palette.primary.main,
  },
  watch: {
    stroke: theme.palette.primary.secondary,
  },
  read: {
    stroke: theme.palette.primary.tertiary,
    '&$shiftHidden': {
      backgroundColor: theme.palette.secondary.tertiary,
    },
  },
  mixed: {
    stroke: theme.palette.primary.mixed,
  },
  audioThumbnail: {
    width: 48,
    height: 48,
    marginTop: 'auto',
    marginBottom: 'auto',
    borderRadius: '8px',
    [theme.breakpoints.up('md')]: {
      width: 100,
      height: 100,
      borderRadius: '20px',
    },
  },
  thumbnailContainer: {
    borderRadius: '8px',
    width: 48,
    height: 48,
    position: 'relative',

    zIndex: 1,
    [theme.breakpoints.up('md')]: {
      width: 100,
      height: 100,
      borderRadius: '20px',
    },
    '&::after': {

      backgroundColor: '#307EDA',
      borderRadius: '8px',
      content: '""',
      height: '100%',
      left: '3px',
      position: 'absolute',
      top: '3px',
      width: '100%',
      zIndex: -1,
      [theme.breakpoints.up('md')]: {
        borderRadius: '20px',
        left: '5px',
        top: '5px',
      },
    },
  },

}))

const types = {
  Video: 'watch',
  Audio: 'listen',
  MixedMedia: 'mixed',
}

const UpNextAudioOverlay = ({
  content: {
    title,
    templateKey,
    thumbnail,
    slug,
  },
  timerDuration,
}) => {
  const type = types[templateKey]
  const classes = useStyles()
  const theme = useTheme()
  const [entered, setIsEntered] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setIsEntered(true)
    }, 10)
  }, [])
  const UpNextControls = () => {
    const [mounted, setMounted] = useState(false)
    useEffect(() => {
      setTimeout(() => {
        setMounted(true)
      }, 10)
    }, [])
    const strokeDashoffset = mounted ? 0 : 126.92
    const transition = `stroke-dashoffset ${timerDuration}s linear`

    return (
      <>
        <Box mt={{ md: 'auto' }} display="inline-flex">
          <Box
            ml={{ xs: 'auto', md: 'unset' }}
            display="flex"
            alignItems="center"
            style={{ cursor: 'pointer' }}
            onClick={() => navigate(slug)}
          >
            <Box display="flex" position="relative">
              <PlayCircleIcon />
              <Box
                id="anim"
                width="100%"
                height="100%"
                position="absolute"
                style={{ transform: 'rotate(-90deg) scale(1.07)' }}
              >
                <svg className={classes[type]} viewBox="22 22 44 44">
                  <circle
                    style={{
                      strokeDasharray: '126.920',
                      strokeDashoffset,
                      transition,
                    }}
                    cx="44"
                    cy="44"
                    r="20.2"
                    fill="none"
                    strokeWidth="3.8"
                  />
                </svg>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    )
  }

  return (
    <Box
      display="flex"
      zIndex="20"
      className={`${classes.main} ${entered ? classes.entered : ''}`}
      justifyContent="center"
      alignItems="center"
      position="absolute"
      right={{ ss: 6, sm: 16 }}
      top={-16}
      px={3}
      py={2}

      overflow="hidden"
      style={{ borderRadius: '20px' }}
      width="100%"
    >

      <Box className={classes.thumbnailContainer} mb={1}>
        <Image className={classes.audioThumbnail} imageInfo={thumbnail} />
      </Box>
      <Box display="flex" flexDirection="column" mx={5} width="50%">
        <Box mb={{ xs: 0.5, sm: 1 }}>
          <Typography variant="caption" style={{ color: theme.palette.primary.grey, fontWeight: 'bold' }}>Up Next</Typography>
        </Box>
        <Typography variant="h5">{withEllipsis(title, 25)}</Typography>
      </Box>

      <Box
        flexDirection="column"
        height="100%"
      >
        <UpNextControls />
      </Box>
    </Box>
  )
}

UpNextAudioOverlay.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    templateKey: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    thumbnail: PropTypes.shape({}).isRequired,
  }).isRequired,
  timerDuration: PropTypes.number.isRequired,
}

export default UpNextAudioOverlay
