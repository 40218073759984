import React from 'react'
import PropTypes from 'prop-types'

const HiddenText = ({ id, children }) => <span id={id} style={{ display: 'none' }} role="presentation">{children}</span>

HiddenText.propTypes = {
  id: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
}

HiddenText.defaultProps = {
  id: null,
}

export default HiddenText
