import React, { Component } from 'react'
import { PropTypes } from 'prop-types'

const defaultState = {
  isLoaded: false,
  audioPlayerProps: {},
  globalAudioPlayerApi: {},
  globalAudioPlayerState: {},
  plyrRefs: [],
  persistAudioPlayerOnPageReload: false,
}

const GlobalContext = React.createContext(defaultState)

class GlobalProvider extends Component {
  constructor(props) {
    super(props)
    this.state = defaultState
  }

  componentDidMount() {
    const audioPlayerProps = JSON.parse(localStorage.getItem('audioPlayerProps'))

    if (audioPlayerProps) {
      this.setState({ audioPlayerProps })
    }
  }

  setLoaded = (isLoaded) => {
    this.setState(() => ({
      isLoaded,
    }))
  }

  onAudioPlayerReady = (globalAudioPlayerApi) => {
    const {
      plyrRefs,
    } = this.state
    // TODO: ensure only a single instance of the same player is added to refs
    this.setState(() => ({
      globalAudioPlayerApi,
      plyrRefs: plyrRefs.concat([globalAudioPlayerApi]),
    }))
  }

  setGlobalAudioPlayerState = (newState) => {
    const {
      globalAudioPlayerState,
    } = this.state

    this.setState({ globalAudioPlayerState: { globalAudioPlayerState, ...newState } })
  }

  addPlayerApiReference = (playerRef) => {
    const {
      plyrRefs,
    } = this.state

    if (plyrRefs.includes(playerRef)) return

    this.setState({ plyrRefs: plyrRefs.concat([playerRef]) })
  }

  pausePlayerReferences =({ except: currPlyr }) => {
    const { plyrRefs } = this.state

    plyrRefs.forEach((plyrRef) => {
      if (plyrRef.id !== currPlyr.id && plyrRef.playing) plyrRef.pause()
    })
  }

  setAudioPlayerProps = (props) => {
    const { audioPlayerProps, persistAudioPlayerOnPageReload, globalAudioPlayerState } = this.state

    if (
      audioPlayerProps.src === props.src
      || window.location.pathname === audioPlayerProps.slug
    ) { return }

    this.setState({
      audioPlayerProps: props,
      globalAudioPlayerState: { ...globalAudioPlayerState, isPlaying: false },
    })

    if (persistAudioPlayerOnPageReload) {
      localStorage.setItem('audioPlayerProps', JSON.stringify(props))
    }
  }

  render() {
    const { children } = this.props
    const {
      audioPlayerProps, globalAudioPlayerApi, isLoaded, globalAudioPlayerState,
    } = this.state

    return (
      <GlobalContext.Provider
        value={{
          audioPlayerProps,
          globalAudioPlayerApi,
          // Anytime a new player instance is created, addPlayerApiReference should be called on it
          addPlayerApiReference: this.addPlayerApiReference,
          // When PlayerApiRefes are played, call onCurrentMediaChange to pause other playerApiRefs
          onCurrentMediaChange: (currPlyr) => this.pausePlayerReferences({ except: currPlyr }),
          globalAudioPlayerState,
          setGlobalAudioPlayerState: this.setGlobalAudioPlayerState,
          setAudioPlayerProps: this.setAudioPlayerProps,
          onAudioPlayerReady: this.onAudioPlayerReady,
          closeAudioPlayer: () => this.setAudioPlayerProps({}),
          isLoaded,
          setLoaded: this.setLoaded,
        }}
      >
        {children}
      </GlobalContext.Provider>
    )
  }
}

GlobalProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
}

export default GlobalContext

export { GlobalProvider }
