const styles = {
  slider: {
    color: 'white',
    transition: 'color 0.25s ease',
    '& .MuiSlider-thumb': {
      transition:
        'box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, left 0.35s ease',
    },
    '&:hover': {
      color: '#307EDA',
    },
  },
}

export default styles
