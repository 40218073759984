import React from 'react'
import Hidden from '@material-ui/core/Hidden'
import SvgIcon from '@material-ui/core/SvgIcon'


const PlayCircle = () => (
  <>
    <Hidden xsUp><p>Play</p></Hidden>
    <SvgIcon style={{ fill: 'transparent' }} fontSize="large" viewBox="0 0 128 128">
      <circle cx="64" cy="64" r="62" stroke="white" strokeWidth="4" />
      <g clipPath="url(#clip0)">
        <path d="M96.3268 60.0033L52.3725 34.6247C49.9468 33.2277 46.9194 34.9739 46.9194 37.7873V88.5251C46.9194 91.319 49.9468 93.0653 52.3725 91.6683L96.3268 66.2897C98.7525 64.8927 98.7525 61.4003 96.3268 60.0033Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="58.0267" height="58.0267" fill="white" transform="translate(43.5195 34.1333)" />
        </clipPath>
      </defs>
    </SvgIcon>
  </>
)

export default PlayCircle
