/* eslint-disable jsx-a11y/media-has-caption */
// TODO: reason for eslint disable...solution not currently available to make subtitles default.
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import {
  Box,
} from '@material-ui/core'
import { PropTypes } from 'prop-types'
import { navigate } from 'gatsby'
import { Location } from '@reach/router'
import {
  setPlyrListeners, newPlyr, plyrId, triggerGtmEvent,
} from './utils'
import PlayBtn from './PlayBtn'
import AudioControlsMobile from './AudioControlsMobile'
import AudioControlsDesktop from './AudioControlsDesktop'


const timerSeconds = 5

const Audio = ({
  id,
  src,
  slug,
  title,
  thumbnail,
  seriesTitle,
  subtitles,
  upNextContent,
  plyr,
  audioMeta,
  setState,
  onReady,
  muted,
  volume,
  isPlaying,
  controls,
  showPreview,
  closeAudioPlayer,
  setGlobalAudioPlayerState,
}) => {
  useEffect(() => {
    onReady()
  }, [])

  return (
    <Location>
      {({ location }) => {
        const showCloseButton = location.pathname !== slug

        return (
          <Box>
            {controls ? (
              <>
                <Box>
                  <PlayBtn
                    outline
                    setIsPlaying={setState}
                    player={plyr}
                    isPlaying={isPlaying}
                  />
                </Box>
                <Box position="relative">
                  <AudioControlsMobile
                    closeAudioPlayer={closeAudioPlayer}
                    setGlobalAudioPlayerState={setGlobalAudioPlayerState}
                    showCloseButton={showCloseButton}
                    thumbnail={thumbnail}
                    title={title}
                    seriesTitle={seriesTitle}
                    playerRef={plyr}
                    isPlaying={isPlaying}
                    setIsPlaying={setState}
                    muted={muted}
                    setMute={setState}
                    showPreview={showPreview}
                    timerSeconds={timerSeconds}
                    upNextContent={upNextContent}
                  />
                </Box>
                <Box position="relative">
                  <AudioControlsDesktop
                    closeAudioPlayer={closeAudioPlayer}
                    setGlobalAudioPlayerState={setGlobalAudioPlayerState}
                    showCloseButton={showCloseButton}
                    thumbnail={thumbnail}
                    title={title}
                    seriesTitle={seriesTitle}
                    upNextContent={upNextContent}
                    playerRef={plyr}
                    isPlaying={isPlaying}
                    setState={setState}
                    audioMeta={audioMeta}
                    volume={volume}
                    showPreview={showPreview}
                    timerSeconds={timerSeconds}
                  />


                </Box>
              </>
            ) : null}
            <audio src={src} crossOrigin="anonymous" id={plyrId(id)}>
              {subtitles ? (
                <track
                  default
                  kind="captions"
                  label={subtitles.label}
                  srcLang={subtitles.srclang}
                  src={subtitles.src}
                />
              ) : null}
              <source src={src} type="audio/mp3" />
            </audio>
          </Box>
        )
      }}
    </Location>
  )
}

Audio.propTypes = {
  id: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  thumbnail: PropTypes.shape({}),
  title: PropTypes.string.isRequired,
  volume: PropTypes.number.isRequired,
  seriesTitle: PropTypes.string,
  plyr: PropTypes.shape({}),
  audioMeta: PropTypes.shape({}).isRequired,
  setState: PropTypes.func.isRequired,
  onReady: PropTypes.func.isRequired,
  closeAudioPlayer: PropTypes.func,
  setGlobalAudioPlayerState: PropTypes.func,
  muted: PropTypes.bool.isRequired,
  isPlaying: PropTypes.bool.isRequired,
  subtitles: PropTypes.shape({
    label: PropTypes.string,
    srclang: PropTypes.string,
    src: PropTypes.string,
  }),
  upNextContent: PropTypes.shape({
    previous: PropTypes.shape({
      slug: PropTypes.string,
      title: PropTypes.string,
    }),
    next: PropTypes.shape({ slug: PropTypes.string, title: PropTypes.string }),
  }),
  controls: PropTypes.bool,
  showPreview: PropTypes.bool,
}

Audio.defaultProps = {
  plyr: null,
  closeAudioPlayer: null,
  setGlobalAudioPlayerState: null,
  subtitles: null,
  thumbnail: null,
  seriesTitle: null,
  upNextContent: null,
  controls: true,
  showPreview: false,
}

class AudioPlayer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      plyr: undefined,
      duration: undefined,
      volume: 1,
      currentTime: undefined,
      muted: false,
      showPreview: false,
    }

    this.windowAvailable = typeof window !== 'undefined'
    if (this.windowAvailable) this.el = document.createElement('div')
    this.onReady = this.onReady.bind(this)
    this.setState = this.setState.bind(this)
  }

  componentDidMount() {
    const { id } = this.props
    if (!this.windowAvailable) return

    let rootEl = document.getElementById(id)

    if (!rootEl) {
      rootEl = document.createElement('div')
      rootEl.id = id
      rootEl.style.display = 'none'
      document.body.appendChild(rootEl)
    }

    this.audioRoot = rootEl
    this.audioRoot.appendChild(this.el)
  }

  componentWillUnmount() {
    this.audioRoot.removeChild(this.el)

    if (this.unbindPlyrListeners) {
      this.unbindPlyrListeners()
    }
  }

  onReady() {
    const { setState } = this
    const {
      id,
      src,
      upNextContent,
      onMediaDurationChange,
      onReady,
      onCurrentMediaChange,
      setGlobalAudioPlayerState,
    } = this.props
    const plyr = newPlyr(id)
    const eventTriggered = {}

    setState({ plyr })

    if (onReady) onReady(plyr)

    this.unbindPlyrListeners = setPlyrListeners(plyr, [{
      event: 'ended',
      on: () => {
        setState({
          currentTime: 0,
          isPlaying: false,
        })

        const eventKey = 'listened-100'

        if (!eventTriggered[eventKey]) {
          eventTriggered[eventKey] = true
          triggerGtmEvent('custom-event', 'audio', 'listened-100', src)

          const { duration, currentTime } = this.state
          const { seriesTitle, title } = this.props
          window.dataLayer.push({
            event: 'media_complete',
            media_current_time: Math.round(currentTime),
            media_duration: Math.round(duration),
            media_percent: 100,
            media_provider: 'trinet',
            media_title: title,
            media_url: window.location.href,
            media_series: seriesTitle,
            type: 'audio',
          })
        }

        if (upNextContent && upNextContent.next) {
          this.setState({ showPreview: true })
          setTimeout(() => {
            navigate(upNextContent.next.slug)
            this.setState({ showPreview: false })
          }, timerSeconds * 1000)
        }
      },
    }, {
      event: 'timeupdate',
      on: () => {
        setState({
          duration: plyr.duration,
          currentTime: plyr.currentTime,
        })
        const eventKey = 'listened-'
        const pctListened = Number.parseInt((plyr.currentTime / plyr.duration) * 100, 10)

        if ([25, 50, 75].indexOf(pctListened) > -1 && !eventTriggered[`${eventKey}-${pctListened}`]) {
          eventTriggered[`${eventKey}-${pctListened}`] = true
          triggerGtmEvent('custom-event', 'audio', `listened-${pctListened}`, src)

          const { duration, currentTime } = this.state
          const { seriesTitle, title } = this.props
          window.dataLayer.push({
            event: 'media_progress',
            media_current_time: Math.round(currentTime),
            media_duration: Math.round(duration),
            media_percent: Math.round((currentTime / duration) * 100),
            media_provider: 'trinet',
            media_title: title,
            media_url: window.location.href,
            media_series: seriesTitle,
            type: 'audio',
          })
        }
      },
    }, {
      event: 'loadedmetadata',
      on: () => {
        if (onMediaDurationChange) onMediaDurationChange(Math.round(plyr.duration / 60))
        if (setGlobalAudioPlayerState) setGlobalAudioPlayerState({ isPlaying: false })

        const urlParams = new URLSearchParams(window.location.search)
        const autoplay = urlParams.get('autoplay') === 'true'

        if (autoplay) {
          const clearAutoplayParam = () => window.history.replaceState(
            null,
            document.title,
            window.location.pathname,
          )
          plyr.media
            .play()
            .finally(clearAutoplayParam)
        }

        setState({
          duration: plyr.duration,
          currentTime: plyr.currentTime,
        })
      },
    }, {
      event: 'playing',
      on: () => {
        const eventKey = 'audio-clicked'

        if (!eventTriggered[eventKey]) {
          eventTriggered[eventKey] = true
          triggerGtmEvent('custom-event', 'audio', 'clicked', src)

          const { duration, currentTime } = this.state
          const { seriesTitle, title } = this.props
          window.dataLayer.push({
            event: 'media_start',
            media_current_time: Math.round(currentTime),
            media_duration: Math.round(duration),
            media_percent: Math.round((currentTime / duration) * 100),
            media_provider: 'trinet',
            media_title: title,
            media_url: window.location.href,
            media_series: seriesTitle,
            type: 'audio',
          })
        }
      },
    }, {
      event: 'pause',
      on: () => {
        if (setGlobalAudioPlayerState) setGlobalAudioPlayerState({ isPlaying: false })
        const { duration, currentTime } = this.state
        const { seriesTitle, title } = this.props
        window.dataLayer.push({
          event: 'media_pause',
          media_current_time: Math.round(currentTime),
          media_duration: Math.round(duration),
          media_percent: Math.round((currentTime / duration) * 100),
          media_provider: 'trinet',
          media_title: title,
          media_url: window.location.href,
          media_series: seriesTitle,
          type: 'audio_player',
        })
      },
    },
    {
      event: 'play',
      on: () => {
        if (onCurrentMediaChange) onCurrentMediaChange(plyr)
        if (setGlobalAudioPlayerState) setGlobalAudioPlayerState({ isPlaying: true })
      },
    }])
  }

  render() {
    const { setState } = this
    const {
      plyr, duration, currentTime, muted, volume, showPreview,
    } = this.state
    const {
      id,
      src,
      slug,
      title,
      thumbnail,
      seriesTitle,
      subtitles,
      upNextContent,
      controls,
      closeAudioPlayer,
      setGlobalAudioPlayerState,
      globalAudioPlayerState,
    } = this.props

    if (!this.windowAvailable) return null

    return ReactDOM.createPortal(
      <Audio
        id={id}
        plyr={plyr}
        src={src}
        slug={slug}
        closeAudioPlayer={closeAudioPlayer}
        title={title}
        thumbnail={thumbnail}
        seriesTitle={seriesTitle}
        onReady={this.onReady}
        subtitles={subtitles}
        upNextContent={upNextContent}
        audioMeta={{ duration, currentTime }}
        setState={setState}
        setGlobalAudioPlayerState={setGlobalAudioPlayerState}
        showPreview={showPreview}
        volume={volume}
        muted={muted}
        controls={controls}
        isPlaying={globalAudioPlayerState.isPlaying}
      />,
      this.el,
    )
  }
}

AudioPlayer.propTypes = {
  id: PropTypes.string,
  src: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  thumbnail: PropTypes.shape({}),
  title: PropTypes.string,
  seriesTitle: PropTypes.string,
  onReady: PropTypes.func,
  closeAudioPlayer: PropTypes.func,
  onCurrentMediaChange: PropTypes.func,
  onMediaDurationChange: PropTypes.func,
  setGlobalAudioPlayerState: PropTypes.func,
  globalAudioPlayerState: PropTypes.shape({ isPlaying: PropTypes.bool }),
  subtitles: PropTypes.shape({
    label: PropTypes.string,
    srclang: PropTypes.string,
    src: PropTypes.string,
  }),
  upNextContent: PropTypes.shape({
    previous: PropTypes.shape({
      slug: PropTypes.string,
      title: PropTypes.string,
    }),
    next: PropTypes.shape({ slug: PropTypes.string, title: PropTypes.string }),
  }),
  controls: PropTypes.bool,
}

AudioPlayer.defaultProps = {
  id: 'globalAudioPlayer',
  title: '',
  subtitles: null,
  closeAudioPlayer: null,
  onCurrentMediaChange: null,
  setGlobalAudioPlayerState: null,
  globalAudioPlayerState: {},
  thumbnail: null,
  seriesTitle: null,
  onReady: null,
  upNextContent: null,
  onMediaDurationChange: null,
  controls: true,
}

export default AudioPlayer
