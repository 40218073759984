import React from 'react'
import Hidden from '@material-ui/core/Hidden'
import SvgIcon from '@material-ui/core/SvgIcon'

const Forward = () => (
  <>
    <Hidden xsUp><p>Forward</p></Hidden>
    <SvgIcon fontSize="medium" viewBox="0 0 128 128">
      <path d="M48.2722 77.2709H54.2832V50.1182H50.2758L42.6619 52.514L43.8641 57.3057L48.6729 56.1077V77.2709H48.2722Z" fill="white" />
      <path d="M71.5147 77.67C78.728 77.67 83.5368 71.6804 83.5368 63.6943C83.5368 55.7082 78.728 49.7186 71.5147 49.7186C64.3015 49.7186 59.4927 55.7082 59.4927 63.6943C59.8934 71.6804 64.3015 77.67 71.5147 77.67ZM71.5147 72.479C67.9081 72.479 65.5037 68.8853 65.5037 63.6943C65.5037 58.9027 67.9081 55.3089 71.5147 55.3089C75.1214 55.3089 77.5258 58.9027 77.5258 64.0936C77.5258 68.8853 75.1214 72.479 71.5147 72.479Z" fill="white" />
      <path d="M13.0545 64.0934C15.0581 64.0934 16.6611 65.6906 17.0618 67.6872C19.0655 92.0448 39.9037 111.211 65.15 110.413C89.1942 109.215 109.231 89.649 110.032 65.6906C110.834 39.7358 90.7971 18.5726 65.5508 17.774V21.767C65.5508 22.9649 63.9478 23.7635 63.1464 22.9649L53.128 14.5795C52.3265 14.1802 52.3265 12.9823 53.128 12.1837L63.1464 3.79827C63.9478 2.99966 65.5508 3.79827 65.5508 4.99619V9.78786C96.8081 10.9858 121.253 38.1385 117.646 70.083C114.841 94.8399 94.8044 115.205 69.5581 118C38.3008 121.593 11.4515 98.4337 9.0471 68.4858C9.0471 66.0899 11.0508 64.0934 13.0545 64.0934Z" fill="white" />
      <path d="M50.7235 23.3644L39.9036 14.1804C39.1021 13.7811 39.1021 12.5831 39.9036 11.7845L50.7235 2.6005C51.5249 2.20119 52.3264 2.6005 52.3264 3.39911V22.9651C52.3264 23.7637 51.1242 24.163 50.7235 23.3644Z" fill="white" />
    </SvgIcon>
  </>
)

export default Forward
