import React from 'react'
import Hidden from '@material-ui/core/Hidden'
import SvgIcon from '@material-ui/core/SvgIcon'

const Backward = () => (
  <>

    <Hidden xsUp><p>Backward</p></Hidden>
    <SvgIcon fontSize="medium" viewBox="0 0 128 128">
      <path d="M48.2722 77.2709H54.2832V50.1182H50.2758L42.6619 52.514L43.8641 57.3057L48.6729 56.1077V77.2709H48.2722Z" fill="white" />
      <path d="M71.5146 77.67C78.7278 77.67 83.5367 71.6804 83.5367 63.6943C83.5367 55.7082 78.7278 49.7186 71.5146 49.7186C64.3014 49.7186 59.4926 55.7082 59.4926 63.6943C59.8933 71.6804 64.3014 77.67 71.5146 77.67ZM71.5146 72.479C67.908 72.479 65.5036 68.8853 65.5036 63.6943C65.5036 58.9027 67.908 55.3089 71.5146 55.3089C75.1212 55.3089 77.5256 58.9027 77.5256 64.0936C77.5256 68.8853 75.1212 72.479 71.5146 72.479Z" fill="white" />
      <path d="M113.993 64.0934C111.989 64.0934 110.386 65.6906 109.985 67.6872C107.982 92.0448 87.1433 111.211 61.897 110.413C37.8528 109.215 17.8161 89.649 17.0146 65.6906C16.2131 39.7358 36.2499 18.5726 61.4962 17.774V21.767C61.4962 22.9649 63.0992 23.7635 63.9006 22.9649L73.919 14.5795C74.7205 14.1802 74.7205 12.9823 73.919 12.1837L63.9006 3.79827C63.0992 2.99966 61.4962 3.79827 61.4962 4.99619V9.78786C30.2389 10.9858 5.79402 38.1385 9.40064 70.083C12.2058 94.8399 32.2425 115.205 57.4889 118C88.7462 121.593 115.595 98.4337 118 68.4858C118 66.0899 115.996 64.0934 113.993 64.0934Z" fill="white" />
      <path d="M76.3236 23.3644L87.1435 14.1804C87.945 13.7811 87.945 12.5831 87.1435 11.7845L76.3236 2.6005C75.5222 2.20119 74.7207 2.6005 74.7207 3.39911V22.9651C74.7207 23.7637 75.9229 24.163 76.3236 23.3644Z" fill="white" />
    </SvgIcon>
  </>
)

export default Backward
