import React from 'react'
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import PropTypes from 'prop-types'

const palette = {
  light: '#FFFFFF',
  primary: {
    main: '#307EDA',
    contrastLink: '#1f74d8',
    secondary: '#FF5B16',
    tertiary: '#40A36D',
    contrastTertiary: '#007234',
    dark: '#0C0F11',
    black: '#000000',
    light: '#FFFFFF',
    grey: '#B3BCC5',
    mixed: '#6630DA',
  },
  secondary: {
    main: '#56CCE7',
    secondary: '#FFA4DA',
    tertiary: '#FFBE6D',
    tertiaryHover: '#FF8D00',
    mixed: '#7d61bb',
  },
}

const theme = createMuiTheme({
  palette,
  breakpoints: {
    values: {
      xs: 0,
      ss: 375,
      sm: 600,

      md: 844,
      lg: 1280,
      xl: 1440,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: palette.primary.dark,
          color: palette.primary.light,
          fontFamily: "'Gotham', sans-serif",
          fontSize: '16px',
          minHeight: '100vh',
          lineHeight: 'unset',
          WebkitFontSmoothing: 'auto',
        },
        iframe: {
          border: 'none',
        },
      },
    },
    MuiContainer: {
      root: {
        maxWidth: 1440,
        padding: '0',
        margin: '0 auto',
      },
    },
    MuiInputBase: {
      root: {
        color: palette.primary.light,
      },
      input: {
        padding: '10px',
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: `1px solid ${palette.primary.grey}`,
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: `1px solid ${palette.primary.light}`,
        },
        '&:after': {
          borderBottom: `2px solid ${palette.primary.light}`,
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: palette.primary.grey,
        paddingLeft: '10px',
        '&$focused': {
          color: palette.primary.grey,
          fontWeight: 'bold',
        },
      },
    },
    MuiFormHelperText: {
      root: {
        marginTop: '4px',
        paddingLeft: '10px',
        opacity: 1,
      },
    },
    MuiIconButton: {
      root: {
        color: palette.primary.light,
      },
    },
    MuiSlider: {
      root: {
        '&:hover': {
          '& $track': {
            color: palette.primary.main,
          },
        },
      },
      '&:hover': {
        '& $track': {
          color: palette.primary.main,
        },

      },
      thumb: {
        color: palette.primary.light,
      },
      track: {
        transition: 'color 0.25s ease',
        color: palette.primary.light,
        height: '4px',
        borderRadius: '2px',
      },
      rail: {
        color: 'rgba(255,255,255,0.4)',
        height: '4px',
        borderRadius: '2px',
      },
    },

  },
  spacingAlt: (name) => {
    switch (name) {
      case 'xxxl':
        return '6.25rem'
      case 'xl':
        return '3.75rem'
      case 'lg':
        return '2.5rem'
      case 'md':
        return '1.25rem'
      case 'xs':
        return '0.5rem'
      case 'sm':
      default:
        return '0.75rem'
    }
  },
})
theme.typography.fontFamily = 'Gotham'

theme.typography.body1 = {
  fontSize: '1rem', // 16px
  lineHeight: '150%',
}
theme.typography.body2 = {
  fontSize: '1.125rem', // 18px
  lineHeight: '150%',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
  },
}

theme.typography.h1 = {
  fontSize: '1.625rem',
  fontWeight: 900,
  lineHeight: '120%',
  [theme.breakpoints.up('sm')]: {
    fontSize: '2.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '3.688rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '3.75rem', // 60px
  },
}

theme.typography.h2 = {
  fontSize: '2.25rem',
  [theme.breakpoints.up('lg')]: {
    fontSize: '3rem', // 48px
  },
}

theme.typography.h3 = {
  fontSize: '1.25rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '2.25rem', // 36px
  },
}

theme.typography.h4 = {
  fontSize: '1.25rem',
  lineHeight: '120%',
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.5rem',
  },
}

theme.typography.h5 = {
  fontSize: '1rem',
  lineHeight: '120%',
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.25rem', // 20px
  },
}

theme.typography.h6 = {
  // eyebrow
  fontSize: '0.875rem',
  fontWeight: '600',
  lineHeight: '0.875rem',
}

theme.typography.caption = {
  // eyebrow
  color: palette.primary.light,
  fontSize: '0.875rem',
  lineHeight: '130%',
}

export default function Theme({ children }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

Theme.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
}
