import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Slider from '@material-ui/core/Slider'
import { Box, Typography } from '@material-ui/core'
import { v4 as uuid } from 'uuid'
import styles from './styles'
import HiddenText from '../HiddenText'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  slider: styles.slider,
  timeElapsed: {
    textAlign: 'right',
  },
})

const secondsToMinutes = (t) => Math.floor(t / 60)
const formatSeconds = (t) => {
  let seconds = Math.floor(t % 60)

  if (seconds < 10) seconds = `0${seconds}`

  return seconds
}

export default function DurationSlider({ timeElapsed, duration, onChange }) {
  const classes = useStyles()
  const timeElapsedMinutes = secondsToMinutes(timeElapsed)
  const timeElapsedSeconds = formatSeconds(timeElapsed)
  const durationMinutes = secondsToMinutes(duration)
  const durationSeconds = formatSeconds(duration)
  const sliderLabelId = uuid()
  const fontSize = 12

  return (
    <Box className={classes.root}>
      <Grid
        container
        alignItems="center"
        spacing={2}
      >
        <Grid className={classes.timeElapsed} item xs={2}>
          <Typography variant="body1" style={{ fontSize }}>
            {timeElapsedMinutes}
            :
            {timeElapsedSeconds}
          </Typography>
        </Grid>
        <Grid display="flex" item xs={8}>
          <HiddenText id={sliderLabelId}>Duration Slider</HiddenText>
          <Slider
            className={classes.slider}
            value={timeElapsed}
            max={duration}
            onChange={(e, newValue) => onChange(newValue)}
            aria-labelledby={sliderLabelId}
          />
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body1" style={{ fontSize }}>
            {durationMinutes}
            :
            {durationSeconds}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

DurationSlider.propTypes = {
  timeElapsed: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
}
