
import React from 'react'
import {
  Slider,
  makeStyles,
  Box,
  Grid,
} from '@material-ui/core'
import { PropTypes } from 'prop-types'
import { v4 as uuid } from 'uuid'
import { mute } from './utils'
import styles from './styles'
import HiddenText from '../HiddenText'

const useAudioStyles = makeStyles((theme) => ({
  volumeIcon: {
    cursor: 'pointer',
    display: 'none',
    marginTop: 'auto',
    marginLeft: 'auto',
    marginRight: theme.spacing(1.25),
    marginBottom: 'auto',
    height: 20,
    width: 20,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  slider: { ...styles.slider, width: 80, marginRight: theme.spacing(2.5) },
}))

const Icon = ({
  src, alt, className, onClick, onKeyUp,
}) => (
  <Box
    className={className}
    onClick={onClick}
    onKeyUp={onKeyUp}
  >
    <img
      className={className}
      src={src}
      alt={alt}
    />
  </Box>
)
Icon.propTypes = {
  src: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onKeyUp: PropTypes.func.isRequired,
}

const AudioIcon = ({
  volume, className, muteOptions,
}) => {
  const onClick = () => (volume > 0 ? mute(true, muteOptions) : mute(false, muteOptions))
  const onKeyUp = (e) => (volume > 0
    ? mute(true, { e, ...muteOptions })
    : mute(false, { e, ...muteOptions }))

  switch (true) {
    case volume === 0:
      return (
        <Icon
          className={className}
          onClick={onClick}
          onKeyUp={onKeyUp}
          src="/icons/volumeMute.png"
          muteOptions={muteOptions}
          alt="Muted"
        />
      )
    case volume > 0 && volume < 0.5:
      return (
        <Icon
          className={className}
          onClick={onClick}
          onKeyUp={onKeyUp}
          src="/icons/volumeLow.png"
          muteOptions={muteOptions}
          alt="Volume Low"
        />
      )
    case volume >= 0.5 && volume < 1:
      return (
        <Icon
          className={className}
          onClick={onClick}
          onKeyUp={onKeyUp}
          src="/icons/volumeMed.png"
          muteOptions={muteOptions}
          alt="Volume Med"
        />
      )
    case volume >= 1:
      return (
        <Icon
          className={className}
          onClick={onClick}
          onKeyUp={onKeyUp}
          src="/icons/volumeFull.png"
          muteOptions={muteOptions}
          alt="Volume Max"
        />
      )

    default:
      return null
  }
}

AudioIcon.propTypes = {
  volume: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
  muteOptions: PropTypes.shape({}).isRequired,
}

const AudioVolumeControl = ({
  player,
  volume,
  setState,
  showCloseButton,
}) => {
  const classes = useAudioStyles()
  const playerRef = player
  const muteOptions = { player, setMute: setState }
  const sliderLabelId = uuid()

  return (
    <>
      <Grid item md={3} style={{ display: 'flex', marginLeft: 'auto', marginRight: showCloseButton ? 65 : 0 }}>
        <AudioIcon
          volume={volume}
          className={classes.volumeIcon}
          muteOptions={muteOptions}
        />
        <Box display="flex">
          <HiddenText id={sliderLabelId}>Volume Slider</HiddenText>
          <Slider
            className={classes.slider}
            value={volume}
            step={0.01}
            min={0}
            max={1}
            onChange={(e, newVolume) => {
              playerRef.volume = newVolume
              setState({ volume: newVolume })
            }}
            aria-labelledby={sliderLabelId}
          />
        </Box>
      </Grid>
    </>
  )
}

AudioVolumeControl.propTypes = {
  player: PropTypes.shape({}),
  volume: PropTypes.number.isRequired,
  showCloseButton: PropTypes.bool,
  setState: PropTypes.func.isRequired,
}

AudioVolumeControl.defaultProps = {
  player: null,
  showCloseButton: false,
}

export default AudioVolumeControl
