import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Hidden from '@material-ui/core/Hidden'
import SvgIcon from '@material-ui/core/SvgIcon'

const useStyles = makeStyles((theme) => ({
  icon: {
    fill: 'transparent',
    fontSize: ({ xl }) => (xl ? '4rem' : '2.5rem'),
    [theme.breakpoints.up('sm')]: {
      fontSize: ({ xl }) => (xl ? '8rem' : '2.5rem'),
    },
  },
}))

const Pause = ({ outline, xl }) => {
  const classes = useStyles({ xl })

  return (
    <>
      <Hidden xsUp><p>Pause</p></Hidden>
      <SvgIcon className={classes.icon} viewBox="0 0 128 128">
        {outline ? <circle cx="64" cy="64" r="62" stroke="white" strokeWidth="4" /> : null}
        <rect x="44" y="35" width="16" height="58" rx="4" fill="white" />
        <rect x="68" y="35" width="16" height="58" rx="4" fill="white" />
      </SvgIcon>
    </>
  )
}

Pause.propTypes = {
  outline: PropTypes.bool,
  xl: PropTypes.bool,
}
Pause.defaultProps = {
  outline: false,
  xl: false,
}

export default Pause
