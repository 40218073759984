import 'firebase/auth'
import 'firebase/firestore'
import firebase from 'gatsby-plugin-firebase'
import React from 'react'
import { PropTypes } from 'prop-types'
import { Box } from '@material-ui/core'
import Theme from './src/components/Theme'
import GlobalContext, { GlobalProvider } from './src/context/GlobalContext'
import AudioPlayer from './src/components/media/Audio'

firebase.firestore().settings({
  experimentalForceLongPolling: true,
})

let dataLayerTick = false
let dataLayerScrollPosition = 0
let dataLayerScrollMilestones = {
  20: false,
  40: false,
  60: false,
  80: false,
  100: false,
}

const dataLayerCheckScrollMilestone = () => {
  Object.keys(dataLayerScrollMilestones).forEach((milestone) => {
    const value = dataLayerScrollMilestones[milestone]
    const numericMilestone = parseInt(milestone, 10) / 100
    if (!value && dataLayerScrollPosition >= numericMilestone) {
      dataLayerScrollMilestones[milestone] = true
      window.dataLayer.push({
        event: 'scroll_milestone',
        milestone: numericMilestone,
      })
    }
  })
}

const dataLayerScrollHandler = () => {
  dataLayerScrollPosition = window.scrollY / (document.body.scrollHeight - window.innerHeight)

  if (!dataLayerTick) {
    window.requestAnimationFrame(() => {
      dataLayerCheckScrollMilestone()
      dataLayerTick = false
    })
  }

  dataLayerTick = true
}

const dataLayerResetScroll = () => {
  dataLayerTick = false
  dataLayerScrollPosition = 0
  dataLayerScrollMilestones = {
    20: false,
    40: false,
    60: false,
    80: false,
    100: false,
  }
}

export const onRouteUpdate = () => {
  dataLayerResetScroll()
  document.removeEventListener('scroll', dataLayerScrollHandler)
  document.addEventListener('scroll', dataLayerScrollHandler, { passive: true })
}

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = ({ element }) => (
  <GlobalProvider>
    {element}

    <GlobalContext.Consumer>
      {({
        audioPlayerProps,
        setAudioPlayerProps,
        setGlobalAudioPlayerState,
        onCurrentMediaChange,
        onAudioPlayerReady,
        closeAudioPlayer,
      }) => {
        const {
          id,
          plyr,
          src,
          title,
          slug,
          thumbnail,
          seriesTitle,
          onMediaDurationChange,
          subtitles,
          upNextContent,
          controls,
        } = audioPlayerProps

        return (
          <Theme>
            <Box id="plyr-globalAudioPlayer">
              {audioPlayerProps.src && (
              <AudioPlayer
                id={id}
                plyr={plyr}
                closeAudioPlayer={closeAudioPlayer}
                onReady={onAudioPlayerReady}
                setAudioPlayerProps={setAudioPlayerProps}
                onCurrentMediaChange={onCurrentMediaChange}
                setGlobalAudioPlayerState={setGlobalAudioPlayerState}
                src={src}
                title={title}
                slug={slug}
                thumbnail={thumbnail}
                seriesTitle={seriesTitle}
                subtitles={subtitles}
                upNextContent={upNextContent}
                onMediaDurationChange={onMediaDurationChange}
                controls={controls}
              />
              )}
            </Box>
          </Theme>
        )
      }}
    </GlobalContext.Consumer>
  </GlobalProvider>
)

wrapRootElement.propTypes = {
  element: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
}
