import React from 'react'
import {
  Typography,
  makeStyles,
  Box,
  Grid,
} from '@material-ui/core'
import { PropTypes } from 'prop-types'
import CloseIcon from '@material-ui/icons/Close'
import Image from '../PreviewCompatibleImage'
import PlayBtn from './PlayBtn'
import MarqueeTitle from './MarqueeTitle'
import UpNextAudioOverlay from './UpNextAudioOverlay'

const useAudioStyles = (showCloseButton) => (makeStyles((theme) => ({
  playerControlsMobile: {
    borderColor: theme.palette.primary.light,
    borderTopWidth: 2,
    borderTopStyle: 'solid',
    display: 'flex',
    zIndex: 1,
    position: 'fixed',
    bottom: 0,
    left: 0,
    height: 74,
    width: '100%',
    padding: 0,
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.primary.dark,
    [theme.breakpoints.up('md')]: {
      display: 'none',
      height: 146,
    },
  },
  playerLabel: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    marginBottom: 'auto',
    marginTop: 'auto',
    marginLeft: theme.spacing(1.5),
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(3.75),
    },
  },
  thumbnailContainer: {
    borderRadius: '10px',
    height: '48px',
    position: 'relative',
    width: '48px',
    zIndex: 1,
    '&::after': {
      backgroundColor: '#307EDA',
      borderRadius: '10px',
      content: '""',
      height: '100%',
      left: '3px',
      position: 'absolute',
      top: '3px',
      width: '100%',
      zIndex: -1,
    },
  },
  audioThumbnail: {
    borderRadius: '10px',
    height: 48,
    marginBottom: 'auto',
    marginTop: 'auto',
    width: 48,
    [theme.breakpoints.up('lg')]: {
      width: 100,
      height: 100,
    },
  },
  metaContainer: {
    paddingLeft: 16,
    [theme.breakpoints.up('md')]: {
      padding: 0,
      maxWidth: 405,
      paddingLeft: theme.spacing(2.5),
    },
  },
  title: {
    fontSize: 12,
    [theme.breakpoints.up('md')]: {
      fontSize: 24,
    },
  },
  closeIcon: {
    fontSize: '24px',
    cursor: 'pointer',
  },
  seriesTitle: {
    marginTop: theme.spacing(0.5),
    fontSize: '8px',
  },
  playIconFixedContainer: {
    display: 'flex',
    marginRight: theme.spacing(showCloseButton ? 6 : 1.5),
  },
})))()

const AudioControlsMobile = ({
  title,
  thumbnail,
  closeAudioPlayer,
  showCloseButton,
  seriesTitle,
  setGlobalAudioPlayerState,
  isPlaying,
  playerRef,
  timerSeconds,
  showPreview,
  upNextContent,
}) => {
  const classes = useAudioStyles(showCloseButton)

  return (
    <Grid className={classes.playerControlsMobile} container>
      <Grid className={classes.playerLabel} item xs={8}>
        {thumbnail ? (
          <Box className={classes.thumbnailContainer}>
            <Image className={classes.audioThumbnail} imageInfo={thumbnail} alt="Audio thumbnail" />
          </Box>
        ) : null}
        <Box pl={1} className={classes.metaContainer}>
          <MarqueeTitle title={title} />
          {seriesTitle ? (
            <Typography variant="body1" className={classes.seriesTitle}>
              {seriesTitle}
            </Typography>
          ) : null}
        </Box>
      </Grid>
      <Grid className={classes.playIconFixedContainer} item xs>
        <Box display={{ xs: 'initial', md: 'none' }} ml="auto">
          <PlayBtn
            setIsPlaying={setGlobalAudioPlayerState}
            player={playerRef}
            isPlaying={isPlaying}
          />
        </Box>
      </Grid>
      {showCloseButton && closeAudioPlayer && (
      <Box position="absolute" style={{ top: 10, right: 10 }}>
        <CloseIcon className={classes.closeIcon} onClick={() => closeAudioPlayer({})} />
      </Box>
      )}
      {showPreview
        && (
        <UpNextAudioOverlay
          content={upNextContent.next}
          timerDuration={timerSeconds}
        />
        )}
    </Grid>
  )
}

AudioControlsMobile.propTypes = {
  thumbnail: PropTypes.shape({}),
  playerRef: PropTypes.shape({}),
  title: PropTypes.string.isRequired,
  closeAudioPlayer: PropTypes.func,
  showCloseButton: PropTypes.bool,
  seriesTitle: PropTypes.string,
  isPlaying: PropTypes.bool,
  setGlobalAudioPlayerState: PropTypes.func.isRequired,
  upNextContent: PropTypes.shape({
    previous: PropTypes.shape({ path: PropTypes.string, title: PropTypes.string }),
    next: PropTypes.shape({ path: PropTypes.string, title: PropTypes.string }),
  }),
  showPreview: PropTypes.bool,
  timerSeconds: PropTypes.number.isRequired,
}

AudioControlsMobile.defaultProps = {
  playerRef: null,
  isPlaying: false,
  thumbnail: null,
  seriesTitle: null,
  upNextContent: null,
  closeAudioPlayer: null,
  showPreview: false,
  showCloseButton: true,
}

export default AudioControlsMobile
