import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

const PreviewCompatibleImage = ({
  imageInfo,
  style = {},
  className,
  onLoad = () => {},
}) => {
  let imageData = imageInfo
  if (!imageData) return null
  if (typeof imageData === 'string') {
    imageData = {
      image: imageData.split('/static')[1],
    }
  }

  const { alt = '', childImageSharp, image } = imageData

  if (!!image && !!image.childImageSharp) {
    return (
      <Img
        className={className}
        style={style}
        fluid={image.childImageSharp.fluid}
        alt={alt}
        onLoad={onLoad}
      />
    )
  }

  if (childImageSharp) {
    return (
      <Img
        className={className}
        style={style}
        fluid={childImageSharp.fluid}
        alt={alt}
        onLoad={onLoad}
      />
    )
  }

  if (!!image && typeof image === 'string') {
    return (
      <img
        className={className}
        style={{ width: '100%', objectFit: 'cover', ...style }}
        x="true"
        src={image}
        alt={alt}
      />
    )
  }

  return null
}

PreviewCompatibleImage.defaultProps = {
  style: {},
  className: null,
  onLoad: () => {},
}

PreviewCompatibleImage.propTypes = {
  imageInfo: PropTypes.shape({
    alt: PropTypes.string,
    childImageSharp: PropTypes.object,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    style: PropTypes.object,
  }).isRequired,
  onLoad: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.shape({}),
}

export default PreviewCompatibleImage
