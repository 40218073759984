
export const newPlyr = (id, options) => { /* eslint-disable global-require */
  const Plyr = require('../../../node_modules/plyr/dist/plyr.polyfilled.js')
  return new Plyr(`#plyr-${id}`, {
    controls: undefined,
    volume: 0.5,
    ...options,
  })
}

export const plyrId = (id) => `plyr-${id}`
export const setPlyrListeners = (plyr, listeners) => {
  listeners.forEach(({ event, on }) => {
    plyr.on(event, on)
  })

  return () => listeners.forEach(({ event, on, off }) => {
    if (off) off()
    plyr.off(event, on)
  })
}

export const triggerGtmEvent = (event, category, action, label) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event,
      category,
      action,
      label,
    })
  }
}


export const mute = (muted, {
  e, setMute, player,
}) => {
  if (e && e.keyCode !== 13) return
  const playerRef = player
  const volume = muted ? 0 : 1
  playerRef.volume = volume
  setMute({ muted, volume })
}
