
import React from 'react'
import { PropTypes } from 'prop-types'
import {
  Box, makeStyles,
} from '@material-ui/core'
import PlayIcon from '../graphics/player/Play'
import PauseIcon from '../graphics/player/Pause'


const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '50%',
    transition: 'background 0.25s ease',
    '&:hover': { background: theme.palette.primary.main },
  },

}))

const PlayBtn = ({
  outline,
  isPlaying,
  player,
  setIsPlaying,
  xl,
}) => {
  const classes = useStyles()

  return (
    <Box
      display="flex"
      className={classes.root}
      onClick={() => {
        const method = isPlaying || player.playing ? 'pause' : 'play'

        if (setIsPlaying) setIsPlaying({ isPlaying: !player.playing })

        player[method]()
      }}
      style={{ cursor: 'pointer' }}
    >
      {isPlaying || (player && player.playing) ? (
        <PauseIcon
          outline={outline}
          xl={xl}
        />
      ) : (
        <PlayIcon
          outline={outline}
          xl={xl}
        />
      )}
    </Box>
  )
}

PlayBtn.propTypes = {
  isPlaying: PropTypes.bool,
  outline: PropTypes.bool,
  xl: PropTypes.bool,
  player: PropTypes.shape({
    play: PropTypes.func,
    pause: PropTypes.func,
    playing: PropTypes.bool,
  }),
  setIsPlaying: PropTypes.func,
}

PlayBtn.defaultProps = {
  isPlaying: false,
  setIsPlaying: null,
  outline: false,
  xl: false,
  player: { playing: false },
}

export default PlayBtn
