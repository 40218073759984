
import React from 'react'
import {
  Typography,
  makeStyles,
  Box,
  Grid,
  Tooltip,
} from '@material-ui/core'
import { PropTypes } from 'prop-types'
import { navigate } from 'gatsby'
import CloseIcon from '@material-ui/icons/Close'
import Image from '../PreviewCompatibleImage'
import DurationSlider from './DurationSlider'
import ForwardIcon from '../graphics/player/Forward'
import BackwardIcon from '../graphics/player/Backward'
import NextPreviousIcon from '../graphics/player/NextPrevious'
import { withEllipsis } from '../../utils/string'
import PlayBtn from './PlayBtn'
import AudioVolumeControl from './AudioVolumeControl'
import MarqueeTitle from './MarqueeTitle'
import UpNextAudioOverlay from './UpNextAudioOverlay'

const useAudioStyles = makeStyles((theme) => ({
  playerControlsDesktop: {
    borderColor: theme.palette.primary.light,
    borderTopWidth: 2,
    borderTopStyle: 'solid',
    display: 'none',
    zIndex: 1000,
    position: 'fixed',
    bottom: 0,
    left: 0,
    height: 92,
    width: '100%',
    padding: 0,
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.primary.dark,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  playerLabel: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    maxWidth: '25%',
    marginRight: 'auto',
    marginLeft: theme.spacing(2.5),
  },
  audioThumbnail: {
    width: 48,
    height: 48,
    marginTop: 'auto',
    marginBottom: 'auto',
    borderRadius: '20px',
    [theme.breakpoints.up('md')]: {
      width: 64,
      height: 64,
    },
  },
  thumbnailContainer: {
    borderRadius: '20px',
    height: '64px',
    position: 'relative',
    width: '64px',
    zIndex: 1,
    '&::after': {
      backgroundColor: '#307EDA',
      borderRadius: '20px',
      content: '""',
      height: '100%',
      left: '2px',
      position: 'absolute',
      top: '2px',
      width: '100%',
      zIndex: -1,
    },
  },
  metaContainer: {
    [theme.breakpoints.up('md')]: {
      padding: 0,
      maxWidth: 405,
      paddingLeft: theme.spacing(2.5),
    },
  },
  title: {
    fontSize: 12,
    [theme.breakpoints.up('md')]: {
      fontSize: 24,
    },
  },
  seriesTitle: {
    marginTop: theme.spacing(1),
    fontSize: '12px',
  },
  icon: {
    cursor: 'pointer',
    marginLeft: theme.spacing(1.15),
    marginRight: theme.spacing(1.15),
    height: 24,
    width: 24,
  },
  playerControlsMain: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingTop: theme.spacing(1.5),
  },
  arrow: {
    color: theme.palette.primary.light,
  },
  closeIcon: {
    fontSize: '32px',
    cursor: 'pointer',
  },
  tooltip: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.dark,
  },
}))

const AudioControlsDesktop = ({
  closeAudioPlayer,
  title,
  showCloseButton,
  thumbnail,
  seriesTitle,
  upNextContent,
  playerRef,
  isPlaying,
  audioMeta,
  volume,
  setGlobalAudioPlayerState,
  setState,
  showPreview,
  timerSeconds,
}) => {
  const classes = useAudioStyles()
  const tooltipClasses = { arrow: classes.arrow, tooltip: classes.tooltip }
  const player = playerRef
  const skip = (direction) => {
    let newTime
    if (direction === 'forwards') {
      newTime = player.currentTime - 10
      if (newTime < 0) newTime = 0
    } else {
      newTime = player.currentTime + 10
      if (newTime > player.duration) newTime = player.duration
    }

    setState({
      duration: player.duration,
      currentTime: newTime,
    })
    player.currentTime = Math.floor(newTime)
  }
  const skipForwards = () => skip('forwards')
  const skipBackwards = () => skip('backwards')

  return (
    <Grid className={classes.playerControlsDesktop} container>
      <Grid className={classes.playerLabel} item xs>
        {thumbnail ? (
          <Box className={classes.thumbnailContainer}>
            <Image className={classes.audioThumbnail} imageInfo={thumbnail} />
          </Box>
        ) : null}
        <Box pl={1} className={classes.metaContainer}>
          <MarqueeTitle title={title} />
          {seriesTitle ? (
            <Typography variant="body2" className={classes.seriesTitle}>
              {withEllipsis(seriesTitle)}
            </Typography>
          ) : null}
        </Box>
      </Grid>
      <Grid item md={4} className={classes.playerControlsMain}>
        <Box display="flex" alignItems="center">
          <Tooltip
            classes={tooltipClasses}
            title="Previous"
            aria-label="previous"
            placement="left-start"
            arrow
          >
            <Box
              className={classes.icon}
              onClick={() => {
                navigate(`${upNextContent.previous.slug}?autoplay=true`)
              }}
              style={{ visibility: upNextContent.previous && upNextContent.previous.title !== '' ? 'initial' : 'hidden' }}
            >
              <NextPreviousIcon reverse id="previous" />
            </Box>
          </Tooltip>
          <Box
            className={classes.icon}
            onClick={skipForwards}
          >
            <ForwardIcon />
          </Box>
          <PlayBtn
            setIsPlaying={setGlobalAudioPlayerState}
            player={player}
            isPlaying={isPlaying}
          />
          <Box
            className={classes.icon}
            onClick={skipBackwards}
          >
            <BackwardIcon />
          </Box>
          <Tooltip
            classes={tooltipClasses}
            title="Next"
            aria-label="next"
            placement="right-start"
            arrow
          >
            <Box
              className={classes.icon}
              onClick={() => {
                navigate(`${upNextContent.next.slug}?autoplay=true`)
              }}
              style={{ visibility: upNextContent.next && upNextContent.next.title !== '' ? 'initial' : 'hidden' }}
            >
              <NextPreviousIcon id="next" />
            </Box>
          </Tooltip>
        </Box>
        <Box display="flex" alignItems="center" maxWidth="590px" width="100%">
          {audioMeta.currentTime >= 0 ? (
            <DurationSlider
              timeElapsed={audioMeta.currentTime}
              duration={audioMeta.duration}
              onChange={(timeElapsed) => {
                setState({
                  duration: player.duration,
                  currentTime: timeElapsed,
                })
                player.currentTime = Math.floor(timeElapsed)
              }}
            />
          ) : null}
        </Box>
        {showCloseButton && closeAudioPlayer && (
        <Box position="absolute" style={{ top: 15, right: 20 }}>
          <CloseIcon className={classes.closeIcon} onClick={() => closeAudioPlayer({})} />
        </Box>
        )}
      </Grid>
      <AudioVolumeControl
        player={player}
        volume={volume}
        setState={setState}
        showCloseButton={showCloseButton}
      />
      {showPreview
        && (
        <UpNextAudioOverlay
          content={upNextContent.next}
          timerDuration={timerSeconds}
        />
        )}
    </Grid>
  )
}

AudioControlsDesktop.propTypes = {
  thumbnail: PropTypes.shape({}),
  playerRef: PropTypes.shape({}),
  title: PropTypes.string.isRequired,
  showCloseButton: PropTypes.bool,
  seriesTitle: PropTypes.string,
  volume: PropTypes.number.isRequired,
  closeAudioPlayer: PropTypes.func,
  setState: PropTypes.func.isRequired,
  setGlobalAudioPlayerState: PropTypes.func.isRequired,
  audioMeta: PropTypes.shape({
    currentTime: PropTypes.number,
    duration: PropTypes.number,
  }).isRequired,
  isPlaying: PropTypes.bool,
  upNextContent: PropTypes.shape({
    previous: PropTypes.shape({ slug: PropTypes.string, title: PropTypes.string }),
    next: PropTypes.shape({ slug: PropTypes.string, title: PropTypes.string }),
  }),
  showPreview: PropTypes.bool,
  timerSeconds: PropTypes.number.isRequired,
}

AudioControlsDesktop.defaultProps = {
  isPlaying: false,
  playerRef: null,
  thumbnail: null,
  closeAudioPlayer: null,
  seriesTitle: null,
  showPreview: false,
  upNextContent: null,
  showCloseButton: true,
}

export default AudioControlsDesktop
