
import React from 'react'
import {
  Typography,
  makeStyles,
  Box,
} from '@material-ui/core'
import { PropTypes } from 'prop-types'


const useAudioStyles = (title) => makeStyles((theme) => ({
  marquee: {
    display: 'block',
    margin: '0 auto',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '230px',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '350px',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '180px',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '240px',
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: '340px',
    },
  },
  borderLeft: {
    height: '100%',
    width: 20,
    position: 'absolute',
    top: 0,
    left: -1,
    background: 'linear-gradient(90deg, rgba(0,0,0,1), transparent)',
  },
  borderRight: {
    height: '100%',
    width: 20,
    position: 'absolute',
    top: 0,
    right: -1,
    background: 'linear-gradient(-90deg, rgba(0,0,0,1), transparent)',
  },
  marqueeInner: {
    display: 'inline-block',
    paddingLeft: '0%',
    animation: `$marquee ${title.length / 3.7}s linear infinite`,
  },
  '@keyframes marquee': {
    '0%': { transform: 'translate(0, 0)' },
    '100%': { transform: 'translate(-50%, 0)' },
  },
}))

const MarqueeTitle = React.memo(({
  title,
}) => {
  const classes = useAudioStyles(title)()
  const isMarquee = title.length > 30
  const titleText = isMarquee ? `- ${title} - ${title}` : title

  return (
    <Box className={isMarquee ? classes.marquee : ''} position="relative">
      <Typography variant="body2" component="h3" className={`${classes.title} ${isMarquee ? classes.marqueeInner : ''}`}>
        {titleText}
      </Typography>
      {isMarquee && <Box className={classes.borderLeft} />}
      {isMarquee && <Box className={classes.borderRight} />}
    </Box>
  )
})

MarqueeTitle.propTypes = {
  title: PropTypes.string.isRequired,
}

export default MarqueeTitle
