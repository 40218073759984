import React from 'react'
import Hidden from '@material-ui/core/Hidden'
import SvgIcon from '@material-ui/core/SvgIcon'
import PropTypes from 'prop-types'

const NextPrevious = ({ id, reverse }) => {
  const label = reverse ? 'Previous' : 'Next'

  return (
    <>
      <Hidden xsUp><p>{label}</p></Hidden>
      <SvgIcon fontSize="medium" viewBox="0 0 128 128" style={reverse ? { transform: 'scaleX(-1)' } : {}}>
        <g clipPath={`url(#${id})`}>
          <path d="M80.8074 60.0033L36.8531 34.6247C34.4273 33.2277 31.4 34.9739 31.4 37.7873V88.5251C31.4 91.319 34.4273 93.0653 36.8531 91.6683L80.8074 66.2897C83.2331 64.8927 83.2331 61.4003 80.8074 60.0033Z" fill="white" />
        </g>
        <rect x="83" y="35" width="16" height="58" rx="4" fill="white" />
        <defs>
          <clipPath id={id}>
            <rect width="58.0267" height="58.0267" fill="white" transform="translate(28 34.1333)" />
          </clipPath>
        </defs>
      </SvgIcon>
    </>
  )
}

NextPrevious.propTypes = {
  id: PropTypes.string.isRequired,
  reverse: PropTypes.bool,
}

NextPrevious.defaultProps = {
  reverse: false,
}

export default NextPrevious
