export const capitalize = (s) => `${s.charAt(0).toUpperCase()}${s.slice(1, s.length)}`

export const withEllipsis = (str = '', limit = 250) => {
  if (str.length <= limit) { return str }

  const subString = str.substr(0, limit - 1)

  return `${subString.substr(0, subString.lastIndexOf(' '))}...`
}

export const camelCaseToWords = (str) => str.replace(/([a-z])([A-Z])/g, '$1 $2')
